/* eslint-disable @angular-eslint/no-input-rename */
import { DOCUMENT } from '@angular/common';
import { Directive, Inject, Input, OnInit, Renderer2, TemplateRef, ViewContainerRef } from '@angular/core';
import { VariationType } from '../domain/models/ab-test-options.model';
import { ExperimentName } from '../domain/models/experiment-name.model';
import { AbTestsService } from '../util-ab-test/ab-test.service';

type TemplateContext = void;

@Directive({
  selector: '[rmaAbTest]',
  standalone: true,
})
export class AbTestDirective implements OnInit {
  @Input({ alias: 'rmaAbTest', required: true })
  public experimentName!: ExperimentName;

  @Input('rmaAbTestARef')
  public aRef: TemplateRef<TemplateContext> | undefined;

  @Input('rmaAbTestBRef')
  public bRef: TemplateRef<TemplateContext> | undefined;

  @Input('rmaAbTestForceAbVariation')
  public forceAbVariation: VariationType | null | undefined;

  public constructor(
    private readonly controlRef: TemplateRef<TemplateContext>,
    private readonly renderer: Renderer2,
    private readonly service: AbTestsService,
    private readonly vcRef: ViewContainerRef,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {}

  public ngOnInit() {
    const { selected, selectedClass } = this.service.getExperiement(this.experimentName);
    const template = this.convertTypeToTemplate(this.forceAbVariation ?? selected);

    this.addClassToBody(selectedClass);

    if (!template) {
      console.log(`No template found for: ${selected}`);
    } else {
      this.embedTemplate(template);
    }
  }

  private embedTemplate(template: TemplateRef<TemplateContext>) {
    this.vcRef.clear();
    const ref = this.vcRef.createEmbeddedView(template);
    ref.markForCheck();
  }

  private convertTypeToTemplate(type: VariationType): TemplateRef<TemplateContext> | undefined {
    switch (type) {
      case 'A':
        return this.aRef;
      case 'B':
        return this.bRef;
      case 'CONTROL':
        return this.controlRef;
      default:
        return undefined;
    }
  }

  private addClassToBody(className: string) {
    if (this.document) {
      this.renderer.addClass(this.document.body, className);
    }
  }
}
